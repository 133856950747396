import React from 'react';
import styled from 'styled-components';
import { usePhoneImages } from '../queries/usePhoneImages.js';
import { RiRobotLine } from 'react-icons/ri';
import { GiTrophyCup } from 'react-icons/gi';
import {
    FAVOURITE_RESTAURANTS,
    ROBOT_DELIVERY,
    INTERACTIVE_MAP,
    REWARDS_PROGRAMS,
} from './featureSubPages/Constants';
import theme from '../../theme/theme';
import { SectionTemplate, PhoneContainer } from './';
import { Heading, Mixins } from '@cheapreats/react-ui';

export const FeaturesPage = () => {
    const query = usePhoneImages().images.nodes;
    return (
        <SectionTemplate
            header={
                <Heading type="h1" color="primary" margin="0 auto 20px auto">
                    Order Ahead with Contactless Payment
                </Heading>
            }
        >
            <ContentContainer>
                <PhoneContainer
                    image={query[FAVOURITE_RESTAURANTS].childImageSharp.fluid}
                    padding="20px"
                    backgroundColor="#295FA6"
                    border={false}
                >
                    <PhoneRow>
                        <Heading type="h4" margin="auto" color="background">
                            FAVOURITE RESTAURANTS
                        </Heading>
                    </PhoneRow>
                </PhoneContainer>
                <PhoneContainer
                    image={query[ROBOT_DELIVERY].childImageSharp.fluid}
                    padding="20px"
                    backgroundColor={theme.colors.primary}
                    border={false}
                >
                    <PhoneRow>
                        <Heading type="h4" color="background">
                            ROB
                        </Heading>

                        <RiRobotLine
                            style={{
                                color: theme.colors.background,
                            }}
                        />
                        <Heading type="h4" color="background">
                            T
                        </Heading>
                    </PhoneRow>
                    <PhoneRow>
                        <Heading type="h4" margin="auto" color="background">
                            DELIVERY
                        </Heading>
                    </PhoneRow>
                </PhoneContainer>
                <PhoneContainer
                    image={query[INTERACTIVE_MAP].childImageSharp.fluid}
                    padding="20px"
                    backgroundColor="#f2c600"
                    border={false}
                >
                    <PhoneRow>
                        <Heading type="h4" margin="auto" color="background">
                            INTERACTIVE MAP
                        </Heading>
                    </PhoneRow>
                </PhoneContainer>
                <PhoneContainer
                    image={query[REWARDS_PROGRAMS].childImageSharp.fluid}
                    padding="20px"
                    backgroundColor="#f57a9b"
                    border={false}
                >
                    <PhoneRow>
                        <Heading type="h4" color="background">
                            REWARDS
                        </Heading>
                        <GiTrophyCup
                            style={{
                                color: theme.colors.background,
                            }}
                        />
                    </PhoneRow>
                    <PhoneRow>
                        <Heading type="h4" color="background">
                            PROGRAMS
                        </Heading>
                    </PhoneRow>
                </PhoneContainer>
            </ContentContainer>
        </SectionTemplate>
    );
};

const ContentContainer = styled.div`
    ${Mixins.flex('row', 'center')};
    ${Mixins.media('tablet', 'flex-wrap: wrap;')}
    ${Mixins.media('phone', Mixins.flex('column', 'center'))}
    width: 100%;
`;

const PhoneRow = styled.div`
    ${Mixins.flex('row', 'center')};
    text-align: center;
`;
