import React from 'react';
import { Layout } from '../components/Layout';

import { FeaturesPage } from '../components/featuresPage.jsx';

const Features = () => (
    <Layout title="Features">
        <FeaturesPage />
    </Layout>
);

export default Features;
